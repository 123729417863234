export const localStorage = typeof window !== 'undefined' && window.localStorage
export function getStorageItem(item) {
  return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
}
export function setStorageItem(item, value) {
  typeof localStorage.setItem !== 'undefined'
    ? localStorage.setItem(item, value)
    : console.log('no localStorage')
}
export function removeStorageItem(item){
  typeof localStorage.removeItem !== 'undefined'
    ? localStorage.removeItem(item)
    : console.log('no localStorage')
}